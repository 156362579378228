import React from "react"
import { Link, graphql } from "gatsby"
import Seo from "../components/SEO"
import ImageWithText from "../components/ImageWithText/ImageWithText"
import Hero from "../components/Hero/Hero"
import BGImg from "../components/LargeBGImg/LargeBGImg"
import TwoColumn from "../components/TwoColumn/TwoColumn"
import ImageWithHeading from "../components/ImageWithHeading/ImageWithHeading"
import ImageGrid from "../components/ImageGrid/ImageGrid"

const Business = ({ data }) => {

  // Grab variable from .md file

  // Hero Sections
  const hero = data.hero.edges[0].node.frontmatter.heroImage.childImageSharp.gatsbyImageData;
  const heroAlt = data.hero.edges[0].node.frontmatter.heroImageAlt;
  const identifier = data.hero.edges[0].node.frontmatter.identifier;
  const heroDescription = data.hero.edges[0].node.frontmatter.description;

  // Large BG Images
  const fortuneContent = data.largeBGImg.edges.filter(item => item.node.frontmatter.title === 'Fortune')
  const stampingContent = data.largeBGImg.edges.filter(item => item.node.frontmatter.title === 'Stamping Grounds')
  const kaufmanBroadContent = data.largeBGImg.edges.filter(item => item.node.frontmatter.title === 'KB Home')
  const sunAmericaContent = data.largeBGImg.edges.filter(item => item.node.frontmatter.title === 'Sun America')

  // Two Column Sections
  const twoColumnElisNew = data.twoColumn.edges.filter(item => item.node.frontmatter.section === 'elisnew')
  const twoColumnAge17 = data.twoColumn.edges.filter(item => item.node.frontmatter.section === 'age17')
  const twoColumnFirstYear = data.twoColumn.edges.filter(item => item.node.frontmatter.section === 'firstyear')
  const twoColumnLucritive = data.twoColumn.edges.filter(item => item.node.frontmatter.section === 'lucritive')
  const honeymoonComponent = data.imageWithText.edges.filter(item => item.node.frontmatter.identifier === 'honeymoon')
  const ebPhoneComponent = data.imageWithText.edges.filter(item => item.node.frontmatter.identifier === 'ebphone')
  const losAngelesComponent = data.imageWithText.edges.filter(item => item.node.frontmatter.identifier === 'losangeles')
  const ebEbonCouchComponent = data.imageWithText.edges.filter(item => item.node.frontmatter.identifier === 'EbonCouch')
  const colonyCastleComponent = data.imageWithText.edges.filter(item => item.node.frontmatter.identifier === 'colonyCastle')
  const buldingcompanyTitle = data.twoColumn.edges.filter(item => item.node.frontmatter.section === 'buldingcompanyTitle')
  const twoColumnBuldinngCo = data.twoColumn.edges.filter(item => item.node.frontmatter.section === 'buldingcompany')
  const kbBuildingComponent = data.imageWithText.edges.filter(item => item.node.frontmatter.identifier === 'kbbuilding')
  const certificateComponent = data.imageWithText.edges.filter(item => item.node.frontmatter.identifier === 'certificate')
  const twoColumnDiversification = data.twoColumn.edges.filter(item => item.node.frontmatter.section === 'diversification')
  const kbStockExchangeComponent = data.imageWithText.edges.filter(item => item.node.frontmatter.identifier === 'kbStockExchange')
  const twoColumnFinancialPerspective = data.twoColumn.edges.filter(item => item.node.frontmatter.section === 'financialperspective')
  const twoColumnDawn = data.twoColumn.edges.filter(item => item.node.frontmatter.section === 'dawn')
  const bestPerformingStocksComponent = data.imageWithText.edges.filter(item => item.node.frontmatter.identifier === 'bestperformingstocks')
  const sunamericasrunComponent = data.imageWithText.edges.filter(item => item.node.frontmatter.identifier === 'sunamericasrun')

  // Image Grids
  const imageGridOneComponent = data.imageGrid.edges.filter(item => item.node.frontmatter.identifier === 'surveyone')
  const imageGridTwoComponent = data.imageGrid.edges.filter(item => item.node.frontmatter.identifier === 'surveytwo')
  const imageGridBoardMeetingComponent = data.imageGrid.edges.filter(item => item.node.frontmatter.identifier === 'boardmeeting')
  const imageGridCenturyCityComponent = data.imageGrid.edges.filter(item => item.node.frontmatter.identifier === 'centurycity')

  const poplethought = data.imageWithHeading.edges.filter(item => item.node.frontmatter.identifier === 'poplethought')
  const onJanurary = data.imageWithHeading.edges.filter(item => item.node.frontmatter.identifier === 'onJanurary')
  const overDesk = data.imageWithHeading.edges.filter(item => item.node.frontmatter.identifier === 'overDesk')


  const positions = {
    rowOneWrapper: {
      classes: "row-wrapper one",
      TextRow1: {
        classes: "mb-200",
          text1: {
            value: "Anything",
            classes: "sixty"
          },
          text2: {
            value: "",
            classes: "empty"
          }
      },
      TextRow2: {
        classes: "empty-height",
          text1: {
            value: "",
            classes: "empty"
          },
          text2: {
            value: "",
            classes: "epmty"
          }
      },
    },
    rowTwoWrapper: {
      classes: "row-wrapper two",
      TextRow1: {
        classes: "empty-height",
          text1: {
            value: "",
            classes: "empty"
          },
          text2: {
            value: " ",
            classes: "empty"
          }
      },
      TextRow2: {
        classes: "empty-height",
          text1: {
            value: "",
            classes: "empty"
          },
          text2: {
            value: "",
            classes: "empty"
          }
      },
      TextRow3: {
        classes: "",
          text1: {
            value: "",
            classes: "empty"
          },
          text2: {
            value: "Else?",
            classes: "thirty Leftauto"
          }
      },
    }
  }
  return (
    <>
      <Seo title={"Eli Broad | Business"} description={"As the first American businessman to found two Fortune 500 companies in different industries, Eli Broad’s success was defined by keen vision, an appetite for risk-taking and an instinct for strong partners."} />
      <Hero heroimage={hero} heroimagealt={heroAlt} identifier={identifier} positions={positions} description={heroDescription} />

      <BGImg content={fortuneContent} />

      <BGImg content={stampingContent} />

      <TwoColumn content={twoColumnElisNew}/>

      <ImageWithHeading content={poplethought} />

      <TwoColumn content={twoColumnAge17} noPadding={true} />

      <ImageWithText content={ebPhoneComponent} alignTop={true} imageFloat={true} />

      <ImageWithText content={ebEbonCouchComponent} alignTop={true} imageFloat={true} />

      <BGImg content={kaufmanBroadContent} />

      <TwoColumn content={twoColumnLucritive} noPadding={true} />

      <ImageWithText content={honeymoonComponent} alignTop={true} imageFloat={true} />

      <ImageWithHeading content={overDesk} />

      <TwoColumn content={twoColumnFirstYear} noPadding={true} />

      <ImageWithText content={colonyCastleComponent} alignTop={true} imageFloat={true} />

      <ImageGrid content={imageGridOneComponent} />

      <ImageWithText content={losAngelesComponent} alignTop={true} imageFloat={false} />

      <ImageGrid content={imageGridTwoComponent} />

      <TwoColumn content={buldingcompanyTitle} type="top" />

      <TwoColumn content={twoColumnBuldinngCo} type="bottom" />

      <ImageWithText content={kbBuildingComponent} alignTop={true} imageFloat={false} />

      <ImageWithText content={certificateComponent} headerContent={true} />

      <TwoColumn content={twoColumnDiversification}/>

      <ImageWithText content={kbStockExchangeComponent} headerContent={false} />

      <ImageGrid content={imageGridBoardMeetingComponent} />

      <TwoColumn content={twoColumnFinancialPerspective}/>

      <BGImg content={sunAmericaContent} />

      <TwoColumn content={twoColumnDawn}/>

      <ImageWithHeading content={onJanurary} />

      <ImageWithText content={bestPerformingStocksComponent} alignTop={true} imageFloat={false} />

      <ImageWithText content={sunamericasrunComponent} />

      <ImageGrid content={imageGridCenturyCityComponent} />

      <div class="continue-link">
        <Link to="/education">Continue to Education</Link>
      </div>

    </>
  )
}

export default Business

export const query = graphql`
  {
    hero: allMarkdownRemark(filter: {frontmatter: {identifier: {eq: "businessHero"}}}) {
      edges {
        node {
          frontmatter {
            heroImage {
              childImageSharp {
                gatsbyImageData(formats: AUTO, layout: FULL_WIDTH, placeholder: BLURRED)
              }
            }
            heroImageAlt
            identifier
            intro
            title
            description
          }
        }
      }
    }
    imageWithText: allMarkdownRemark(filter: {frontmatter: {component: {eq: "imageWithText"}, page: {eq: "business"}}}) {
      edges {
        node {
          ...imageWithTextQuery
        }
      }
    }
    largeBGImg: allMarkdownRemark(filter: {frontmatter: {component: {eq: "LargeBGImg"}, page: {eq: "business"}}}) {
      edges {
        node {
          ...largeBGImgQuery
        }
      }
    }
    twoColumn: allMarkdownRemark(filter: {frontmatter: {page: {eq: "business"}, component: {eq: "twocolumn"}}}) {
      edges {
        node {
          ...twoColumnQuery
        }
      }
    }
    imageWithHeading: allMarkdownRemark(
      filter: {frontmatter: {component: {eq: "imageWithHeading"}, page: {eq: "business"}}}
    ) {
      edges {
        node {
          ...imageWithHeadingQuery
        }
      }
    }
    imageGrid: allMarkdownRemark(filter: {frontmatter: {page: {eq: "business"}, component: {eq: "imageGrid"}}}) {
      edges {
        node {
          ...imageGridQuery
        }
      }
    }
  }
`
